import { HTTPWrapperGetFunction, HTTPWrapperPostFunction, MakeRequestFuntion } from './HTTPWrapperTypes'

class HTTPWrapper {
  static makeRequest: MakeRequestFuntion = async ({
    path,
    method,
    body = {},
    headers = { 'Content-Type': 'application/json' },
    queryParams = {},
    cache = 'default'
  }) => {
    const apiUrl: string = process.env.REACT_APP_GLOBALLYNX_API ?? ''
    let parsedBody: BodyInit | null | undefined
    if (headers['Content-Type'] === 'application/json') parsedBody = JSON.stringify(body)
    else {
      /* Check if formData is accesible */
      if (typeof FormData === 'undefined') {
        return {}
      } else {
        parsedBody = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in body) {
          parsedBody.append(key, body[key] as string | Blob)
        }
      }
    }

    if (method === 'GET') parsedBody = undefined

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const queryParamsString: string = new URLSearchParams(queryParams).toString()
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const serviceUrl = `${apiUrl}${path}${queryParamsString ? `?${queryParamsString}` : ''}`
    const response = await fetch(serviceUrl, {
      method,
      headers,
      body: parsedBody,
      cache
    })
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
    return response.json() as Record<string, any> | Array<Record<string, any>>
  }

  get: HTTPWrapperGetFunction = async ({ path, headers = {}, queryParams = {}, cache = 'default' }) =>
    await HTTPWrapper.makeRequest({ path, method: 'GET', headers, queryParams, cache })

  post: HTTPWrapperPostFunction = async ({ path, body, headers = {}, queryParams = {} }) =>
    await HTTPWrapper.makeRequest({
      path,
      method: 'POST',
      body,
      headers,
      queryParams
    })
}

class HTTPServerWrapper {
  static makeRequest: MakeRequestFuntion = async ({
    path,
    method,
    body = {},
    headers = { 'Content-Type': 'application/json' },
    queryParams = {},
    cache = 'default'
  }) => {
    const apiUrl: string = process.env.REACT_APP_GLOBALLYNX_API ?? ''
    let parsedBody: BodyInit | null | undefined
    parsedBody = JSON.stringify(body)
    if (method === 'GET') parsedBody = undefined

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const queryParamsString: string = new URLSearchParams(queryParams).toString()
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const serviceUrl = `${apiUrl}${path}${queryParamsString ? `?${queryParamsString}` : ''}`
    console.info('serviceUrl', serviceUrl)
    const response = await fetch(serviceUrl, {
      method,
      headers,
      body: parsedBody,
      cache
    })

    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
    return response.json() as Record<string, any> | Array<Record<string, any>>
  }

  get: HTTPWrapperGetFunction = async ({ path, headers = {}, queryParams = {}, cache = 'default' }) =>
    await HTTPServerWrapper.makeRequest({ path, method: 'GET', headers, queryParams, cache })

  post: HTTPWrapperPostFunction = async ({ path, body, headers = {}, queryParams = {} }) =>
    await HTTPServerWrapper.makeRequest({
      path,
      method: 'POST',
      body,
      headers,
      queryParams
    })
}

export default new HTTPWrapper()

export const httpServerWrapper = new HTTPServerWrapper()
